@import "sass-space";

$blue: #0773DD;

body {
  font-family: 'Work Sans', sans-serif;
  //background: #FFFFFF; /* fallback for old browsers */
  //background: -webkit-linear-gradient(right, #FFFFFF, #FFFFFF);
  //background: -moz-linear-gradient(right, #FFFFFF, #FFFFFF);
  //background: -o-linear-gradient(right, #1F88E8, #FFFFFF);
  //background: linear-gradient(to left, #FFFFFF, #FFFFFF);
  //font-family: "Roboto", sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 0;
  font-family: 'Archivo Black', sans-serif;
}

p {
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .carousel {
    h1 {
      font-size: 15px !important;
    }

    p {
      font-size: 10px !important;
    }

    a {
      font-size: 10px !important;
    }
  }
}

.relative {
  position: relative;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.position-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.blue {
  color: $blue;
}

.white {
  color: white;
}

.gray {
  color: #C4C4C4;
}

footer {
  background: #0F1C29;
  color: white;
  padding: 45px 0;

  h4, h5 {
    margin-top: 0;
    font-weight: bold;
  }

  p.blue {
    margin-bottom: 0;
  }
}

.whatsapp-btn {
  display: block;
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 10;
}

.mobilePhone {
  width: 60px;
  height: 45px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

// N A V B A R

.navbar {
  margin-bottom: 0;

  a {
    color: black;
  }

  .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav > li > a:focus, .nav > li > a:hover, .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background: white;
  }

  .benefits {
    background: #f9f9f9;
    color: #555555;
  }

  /*
    .benefits p {
      margin: 10px auto;
      display: table;

      span, i {
        display: table-cell;
        vertical-align: middle;
      }

      span {
        font-size: 18px;
      }

      i {
        font-size: 28px;
        padding-right: 10px;
      }
    }

    .brands .wrapper {
      max-height: 0;
      transition: all 0.3s ease;
    }*/

  .navbar-brand {
    //height: 68px;
    padding: 1px 0;

    img {
      width: 275px;
      height: auto;
    }
  }

  .navbar-toggle .icon-bar {
    background: black;
  }

  .dropdown-menu {
    padding: 0;
  }

  .user-logged {
    .dropdown-menu {
      padding: 14px 10px 14px;
      background: $blue;

      a {
        color: white;
        padding: 10px 15px;
      }
    }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
      background: #0760c0;
    }
  }

  .user-login .dropdown-menu {
    padding: 24px 24px 14px;
    background: $blue;
    width: 265px;

    .user-login-alert {
      font-size: 16px;
    }

    input {
      outline: 0;
      background: #f2f2f2;
      border: 0;
      margin: 0 0 15px;
      padding: 10px 15px;
      width: 100%;
    }

    button {
      outline: 0;
      text-transform: uppercase;
      background: #FFFFFF;
      width: 100%;
      border: 0;
      padding: 15px;
      color: $blue;
      font-size: 14px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }
}

/*
.slick-carousel {
  margin: 15px auto;

  .slick-slide {
    height: auto;
  }

  img {
    padding: 0 15px;
  }
}
*/
.line {
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
}

// F I N    N A V B A R

// H O M E

.header {
  position: relative;

  .banner {
    position: relative;
    left: calc(390px + 50% - 585px);
    height: 560px;
    width: calc(780px + 50% - 585px);
    float: left;
  }

  .content {
    position: relative;
    right: calc(780px + 50% - 585px);
    background: #0773DD;
    width: calc(390px + 50% - 585px);
    float: left;
    height: 560px;
  }

  .info-container {
    max-width: 390px;
    margin-left: auto;
    height: 560px;
  }
}

.info-container {
  background: $blue;
  color: white;
  padding: 50px 30px;
  position: relative;

  .background-img {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
  }
}

.section {
  padding: 50px 0 50px;
}

.btn.white {
  background: white;
  color: $blue;
  border-radius: 20px;
  padding: 6px 50px;
}

.btn.black {
  background: black;
  color: white;
  border-radius: 20px;
  padding: 6px 50px;
}

.btn.square {
  color: black;
  background: white;
  border-color: black;
  border-radius: 0;
}

.maps-container {
  height: 600px;
}

.usa {
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;

  .relative {
    height: 600px;
  }
}

.germany {
  width: 70%;
  position: absolute;
  top: 0;
  right: 0;

  .relative {
    height: 600px;
  }
}

.map-usa {
  position: absolute;
  top: 25%;
}

.map-germany {
  position: absolute;
  top: 25%;
  right: 0;
}

.map-usa, .map-germany {
  color: white;
  z-index: 10;

  .info {
    width: 80%;
  }

  h3 {
    font-weight: bolder;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    text-align: justify;
  }
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 510px;

  .info-container {

  }
}

// F I N    H O M E

// A B O U T

.member-profile {
  width: 200px;
  display: inline-block;
  margin: 0 20px 20px;
  float: none;

  img {
    width: 100%;
  }
}

.value {
  text-align: center;
  transition: all 0.3s;

  &:hover {
    color: $blue;
    transition: all 0.3s;
  }

  i {
    font-size: 80px;
    margin-bottom: 20px;
  }
}

// F I N    A B O U T

// C O N T A C T

.contact-header {
  position: relative;

  #map {
    height: 460px;
    position: relative;
    float: left;
    width: calc(390px + 50% - 585px);
  }

  .content {
    position: relative;
    width: calc(780px + 50% - 585px);
    background: #0773DD;
    float: left;
    height: 460px;
  }

  .info-container {
    max-width: 780px;
    margin-right: auto;
    height: 460px;
  }
}

.contact {
  padding: 18px;
  transition: all 0.3s;
  display: inline-block;
  margin: 0 5px 5px;
  width: 340px;

  > div {
    display: table-cell;
    vertical-align: middle;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  i {
    font-size: 60px;
    margin-right: 10px;
  }

  &:hover {
    background: #0C488F;
    color: white;
    transition: all 0.3s;
  }
}

// F I N    C O N T A C T

// Q U O T A T I O N

.quotation-background {
  height: 200px;
  float: left;
  width: calc(390px + 50% - 585px);
}

.quotation-form {
  width: calc(780px + 50% - 585px);
  float: left;

  .form .form-control {
    margin-bottom: 24px;
  }

  .remove-item {
    background: red;
    color: white;
    padding: 8px 12px;
  }

  .foot {
    margin-top: 30px;

    p {
      margin-bottom: 0;
      font-weight: 300;
    }
  }
}

// F I N    Q U O T A T I O N

@media (max-width: 1199px) {
  .header .content {
    width: calc(324px + 50% - 485px);
    right: calc(646px + 50% - 485px);
  }

  .header .info-container {
    max-width: 324px;
  }

  .header .banner {
    width: calc(646px + 50% - 485px);
    left: calc(324px + 50% - 485px);
  }

  .quotation-background {
    width: calc(324px + 50% - 485px);
  }

  .quotation-form {
    width: calc(646px + 50% - 485px);
  }
}

@media (max-width: 991px) {
  h2 {
    font-size: 18px;
  }

  .header .banner {
    left: unset;
  }

  .header .content {
    right: unset;
  }

  .header .banner, .header .content, .contact-header #map, .contact-header .content, .quotation-background, .quotation-form, .quotation-form > form {
    width: 100%;
  }

  .header .content, .contact-header .content {
    height: auto;
  }

  .header .info-container, .contact-header .info-container {
    max-width: unset;
    margin: auto;
  }

  .header .banner {
    height: 50vw;
  }

  .contact-header #map {
    height: 200px;
  }

  .header .container {
    position: unset;
    left: unset;
    transform: unset;
    width: 100%;
  }

  .info-container {
    padding: 30px 10%;
    height: auto !important;
  }

  .maps-container {
    height: 900px;
  }

  .usa, .germany {
    position: unset;
    width: 100%;
  }

  .map-germany {
    top: 5%;
  }

  .map-usa, .map-germany {
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }

  .usa .relative, .germany .relative {
    height: 450px;

    img.img-responsive {
      width: 100%;
    }
  }

  .value {
    margin-bottom: 30px;
  }

  .section {
    padding: 30px 0 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar .benefits {
    display: none;
  }

  .navbar-right {
    float: left !important;
  }

  .gallery-wrap {
    height: 320px;
  }
}

@media (max-width: 767px) {

  .navbar .navbar-brand img {
    margin-bottom: 5px;
  }

  .navbar-brand {
    float: unset;
  }

  .nav > li {
    text-align: center;
  }

  .navbar .user-login .dropdown-menu, .navbar .user-logged .dropdown-menu {
    margin: 0 auto 20px;
    width: fit-content;
    border-radius: 0;
  }

  .brands {
    width: 95%;
  }

  .benefits {
    display: none;
  }
}

.has-warning {
  border-color: yellow !important;
  color: yellow !important;
}

.loading {
  background-size: 20px 20px;
  background-position-x: 97%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-image: url("/impopartes/web/images/admin/ajax-loader-min.gif");
}

.register-link {
  color: white !important;
}

// P A Y M E N T

.payment-box {
  border-radius: 3px;
  border-top: 3px solid #3C53E0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  min-height: 400px;
  margin: 4px;

  padding: 16px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

}

// F I N   P A Y M E N T