.xs-p-5 {
  padding: 5px;
}

.xs-p-10 {
  padding: 10px;
}

.xs-p-15 {
  padding: 15px;
}

.xs-p-20 {
  padding: 20px;
}

.xs-p-30 {
  padding: 30px;
}

.xs-p-40 {
  padding: 40px;
}

.xs-p-50 {
  padding: 50px;
}

.xs-pt-5 {
  padding-top: 5px;
}

.xs-pt-10 {
  padding-top: 10px;
}

.xs-pt-15 {
  padding-top: 15px;
}

.xs-pt-20 {
  padding-top: 20px;
}

.xs-pt-30 {
  padding-top: 30px;
}

.xs-pt-40 {
  padding-top: 40px;
}

.xs-pt-50 {
  padding-top: 50px;
}

.xs-pr-5 {
  padding-right: 5px;
}

.xs-pr-10 {
  padding-right: 10px;
}

.xs-pr-15 {
  padding-right: 15px;
}

.xs-pr-20 {
  padding-right: 20px;
}

.xs-pr-30 {
  padding-right: 30px;
}

.xs-pr-40 {
  padding-right: 40px;
}

.xs-pr-50 {
  padding-right: 50px;
}

.xs-pb-5 {
  padding-bottom: 5px;
}

.xs-pb-10 {
  padding-bottom: 10px;
}

.xs-pb-15 {
  padding-bottom: 15px;
}

.xs-pb-20 {
  padding-bottom: 20px;
}

.xs-pb-30 {
  padding-bottom: 30px;
}

.xs-pb-40 {
  padding-bottom: 40px;
}

.xs-pb-50 {
  padding-bottom: 50px;
}

.xs-pl-5 {
  padding-left: 5px;
}

.xs-pl-10 {
  padding-left: 10px;
}

.xs-pl-15 {
  padding-left: 15px;
}

.xs-pl-20 {
  padding-left: 20px;
}

.xs-pl-30 {
  padding-left: 30px;
}

.xs-pl-40 {
  padding-left: 40px;
}

.xs-pl-50 {
  padding-left: 50px;
}

.xs-m-5 {
  margin: 5px;
}

.xs-m-10 {
  margin: 10px;
}

.xs-m-15 {
  margin: 15px;
}

.xs-m-20 {
  margin: 20px;
}

.xs-m-30 {
  margin: 30px;
}

.xs-m-40 {
  margin: 40px;
}

.xs-m-50 {
  margin: 50px;
}

.xs-mt-5 {
  margin-top: 5px;
}

.xs-mt-10 {
  margin-top: 10px;
}

.xs-mt-15 {
  margin-top: 15px;
}

.xs-mt-20 {
  margin-top: 20px;
}

.xs-mt-30 {
  margin-top: 30px;
}

.xs-mt-40 {
  margin-top: 40px;
}

.xs-mt-50 {
  margin-top: 50px;
}

.xs-mr-5 {
  margin-right: 5px;
}

.xs-mr-10 {
  margin-right: 10px;
}

.xs-mr-15 {
  margin-right: 15px;
}

.xs-mr-20 {
  margin-right: 20px;
}

.xs-mr-30 {
  margin-right: 30px;
}

.xs-mr-40 {
  margin-right: 40px;
}

.xs-mr-50 {
  margin-right: 50px;
}

.xs-mb-5 {
  margin-bottom: 5px;
}

.xs-mb-10 {
  margin-bottom: 10px;
}

.xs-mb-15 {
  margin-bottom: 15px;
}

.xs-mb-20 {
  margin-bottom: 20px;
}

.xs-mb-30 {
  margin-bottom: 30px;
}

.xs-mb-40 {
  margin-bottom: 40px;
}

.xs-mb-50 {
  margin-bottom: 50px;
}

.xs-ml-5 {
  margin-left: 5px;
}

.xs-ml-10 {
  margin-left: 10px;
}

.xs-ml-15 {
  margin-left: 15px;
}

.xs-ml-20 {
  margin-left: 20px;
}

.xs-ml-30 {
  margin-left: 30px;
}

.xs-ml-40 {
  margin-left: 40px;
}

.xs-ml-50 {
  margin-left: 50px;
}

@media screen and (min-width: 480px) {
  .sm-p-5 {
    padding: 5px;
  }

  .sm-p-10 {
    padding: 10px;
  }

  .sm-p-15 {
    padding: 15px;
  }

  .sm-p-20 {
    padding: 20px;
  }

  .sm-p-30 {
    padding: 30px;
  }

  .sm-p-40 {
    padding: 40px;
  }

  .sm-p-50 {
    padding: 50px;
  }

  .sm-pt-5 {
    padding-top: 5px;
  }

  .sm-pt-10 {
    padding-top: 10px;
  }

  .sm-pt-15 {
    padding-top: 15px;
  }

  .sm-pt-20 {
    padding-top: 20px;
  }

  .sm-pt-30 {
    padding-top: 30px;
  }

  .sm-pt-40 {
    padding-top: 40px;
  }

  .sm-pt-50 {
    padding-top: 50px;
  }

  .sm-pr-5 {
    padding-right: 5px;
  }

  .sm-pr-10 {
    padding-right: 10px;
  }

  .sm-pr-15 {
    padding-right: 15px;
  }

  .sm-pr-20 {
    padding-right: 20px;
  }

  .sm-pr-30 {
    padding-right: 30px;
  }

  .sm-pr-40 {
    padding-right: 40px;
  }

  .sm-pr-50 {
    padding-right: 50px;
  }

  .sm-pb-5 {
    padding-bottom: 5px;
  }

  .sm-pb-10 {
    padding-bottom: 10px;
  }

  .sm-pb-15 {
    padding-bottom: 15px;
  }

  .sm-pb-20 {
    padding-bottom: 20px;
  }

  .sm-pb-30 {
    padding-bottom: 30px;
  }

  .sm-pb-40 {
    padding-bottom: 40px;
  }

  .sm-pb-50 {
    padding-bottom: 50px;
  }

  .sm-pl-5 {
    padding-left: 5px;
  }

  .sm-pl-10 {
    padding-left: 10px;
  }

  .sm-pl-15 {
    padding-left: 15px;
  }

  .sm-pl-20 {
    padding-left: 20px;
  }

  .sm-pl-30 {
    padding-left: 30px;
  }

  .sm-pl-40 {
    padding-left: 40px;
  }

  .sm-pl-50 {
    padding-left: 50px;
  }

  .sm-m-5 {
    margin: 5px;
  }

  .sm-m-10 {
    margin: 10px;
  }

  .sm-m-15 {
    margin: 15px;
  }

  .sm-m-20 {
    margin: 20px;
  }

  .sm-m-30 {
    margin: 30px;
  }

  .sm-m-40 {
    margin: 40px;
  }

  .sm-m-50 {
    margin: 50px;
  }

  .sm-mt-5 {
    margin-top: 5px;
  }

  .sm-mt-10 {
    margin-top: 10px;
  }

  .sm-mt-15 {
    margin-top: 15px;
  }

  .sm-mt-20 {
    margin-top: 20px;
  }

  .sm-mt-30 {
    margin-top: 30px;
  }

  .sm-mt-40 {
    margin-top: 40px;
  }

  .sm-mt-50 {
    margin-top: 50px;
  }

  .sm-mr-5 {
    margin-right: 5px;
  }

  .sm-mr-10 {
    margin-right: 10px;
  }

  .sm-mr-15 {
    margin-right: 15px;
  }

  .sm-mr-20 {
    margin-right: 20px;
  }

  .sm-mr-30 {
    margin-right: 30px;
  }

  .sm-mr-40 {
    margin-right: 40px;
  }

  .sm-mr-50 {
    margin-right: 50px;
  }

  .sm-mb-5 {
    margin-bottom: 5px;
  }

  .sm-mb-10 {
    margin-bottom: 10px;
  }

  .sm-mb-15 {
    margin-bottom: 15px;
  }

  .sm-mb-20 {
    margin-bottom: 20px;
  }

  .sm-mb-30 {
    margin-bottom: 30px;
  }

  .sm-mb-40 {
    margin-bottom: 40px;
  }

  .sm-mb-50 {
    margin-bottom: 50px;
  }

  .sm-ml-5 {
    margin-left: 5px;
  }

  .sm-ml-10 {
    margin-left: 10px;
  }

  .sm-ml-15 {
    margin-left: 15px;
  }

  .sm-ml-20 {
    margin-left: 20px;
  }

  .sm-ml-30 {
    margin-left: 30px;
  }

  .sm-ml-40 {
    margin-left: 40px;
  }

  .sm-ml-50 {
    margin-left: 50px;
  }
}

@media screen and (min-width: 768px) {
  .md-p-5 {
    padding: 5px;
  }

  .md-p-10 {
    padding: 10px;
  }

  .md-p-15 {
    padding: 15px;
  }

  .md-p-20 {
    padding: 20px;
  }

  .md-p-30 {
    padding: 30px;
  }

  .md-p-40 {
    padding: 40px;
  }

  .md-p-50 {
    padding: 50px;
  }

  .md-pt-5 {
    padding-top: 5px;
  }

  .md-pt-10 {
    padding-top: 10px;
  }

  .md-pt-15 {
    padding-top: 15px;
  }

  .md-pt-20 {
    padding-top: 20px;
  }

  .md-pt-30 {
    padding-top: 30px;
  }

  .md-pt-40 {
    padding-top: 40px;
  }

  .md-pt-50 {
    padding-top: 50px;
  }

  .md-pr-5 {
    padding-right: 5px;
  }

  .md-pr-10 {
    padding-right: 10px;
  }

  .md-pr-15 {
    padding-right: 15px;
  }

  .md-pr-20 {
    padding-right: 20px;
  }

  .md-pr-30 {
    padding-right: 30px;
  }

  .md-pr-40 {
    padding-right: 40px;
  }

  .md-pr-50 {
    padding-right: 50px;
  }

  .md-pb-5 {
    padding-bottom: 5px;
  }

  .md-pb-10 {
    padding-bottom: 10px;
  }

  .md-pb-15 {
    padding-bottom: 15px;
  }

  .md-pb-20 {
    padding-bottom: 20px;
  }

  .md-pb-30 {
    padding-bottom: 30px;
  }

  .md-pb-40 {
    padding-bottom: 40px;
  }

  .md-pb-50 {
    padding-bottom: 50px;
  }

  .md-pl-5 {
    padding-left: 5px;
  }

  .md-pl-10 {
    padding-left: 10px;
  }

  .md-pl-15 {
    padding-left: 15px;
  }

  .md-pl-20 {
    padding-left: 20px;
  }

  .md-pl-30 {
    padding-left: 30px;
  }

  .md-pl-40 {
    padding-left: 40px;
  }

  .md-pl-50 {
    padding-left: 50px;
  }

  .md-m-5 {
    margin: 5px;
  }

  .md-m-10 {
    margin: 10px;
  }

  .md-m-15 {
    margin: 15px;
  }

  .md-m-20 {
    margin: 20px;
  }

  .md-m-30 {
    margin: 30px;
  }

  .md-m-40 {
    margin: 40px;
  }

  .md-m-50 {
    margin: 50px;
  }

  .md-mt-5 {
    margin-top: 5px;
  }

  .md-mt-10 {
    margin-top: 10px;
  }

  .md-mt-15 {
    margin-top: 15px;
  }

  .md-mt-20 {
    margin-top: 20px;
  }

  .md-mt-30 {
    margin-top: 30px;
  }

  .md-mt-40 {
    margin-top: 40px;
  }

  .md-mt-50 {
    margin-top: 50px;
  }

  .md-mr-5 {
    margin-right: 5px;
  }

  .md-mr-10 {
    margin-right: 10px;
  }

  .md-mr-15 {
    margin-right: 15px;
  }

  .md-mr-20 {
    margin-right: 20px;
  }

  .md-mr-30 {
    margin-right: 30px;
  }

  .md-mr-40 {
    margin-right: 40px;
  }

  .md-mr-50 {
    margin-right: 50px;
  }

  .md-mb-5 {
    margin-bottom: 5px;
  }

  .md-mb-10 {
    margin-bottom: 10px;
  }

  .md-mb-15 {
    margin-bottom: 15px;
  }

  .md-mb-20 {
    margin-bottom: 20px;
  }

  .md-mb-30 {
    margin-bottom: 30px;
  }

  .md-mb-40 {
    margin-bottom: 40px;
  }

  .md-mb-50 {
    margin-bottom: 50px;
  }

  .md-ml-5 {
    margin-left: 5px;
  }

  .md-ml-10 {
    margin-left: 10px;
  }

  .md-ml-15 {
    margin-left: 15px;
  }

  .md-ml-20 {
    margin-left: 20px;
  }

  .md-ml-30 {
    margin-left: 30px;
  }

  .md-ml-40 {
    margin-left: 40px;
  }

  .md-ml-50 {
    margin-left: 50px;
  }
}

@media screen and (min-width: 960px) {
  .lg-p-5 {
    padding: 5px;
  }

  .lg-p-10 {
    padding: 10px;
  }

  .lg-p-15 {
    padding: 15px;
  }

  .lg-p-20 {
    padding: 20px;
  }

  .lg-p-30 {
    padding: 30px;
  }

  .lg-p-40 {
    padding: 40px;
  }

  .lg-p-50 {
    padding: 50px;
  }

  .lg-pt-5 {
    padding-top: 5px;
  }

  .lg-pt-10 {
    padding-top: 10px;
  }

  .lg-pt-15 {
    padding-top: 15px;
  }

  .lg-pt-20 {
    padding-top: 20px;
  }

  .lg-pt-30 {
    padding-top: 30px;
  }

  .lg-pt-40 {
    padding-top: 40px;
  }

  .lg-pt-50 {
    padding-top: 50px;
  }

  .lg-pr-5 {
    padding-right: 5px;
  }

  .lg-pr-10 {
    padding-right: 10px;
  }

  .lg-pr-15 {
    padding-right: 15px;
  }

  .lg-pr-20 {
    padding-right: 20px;
  }

  .lg-pr-30 {
    padding-right: 30px;
  }

  .lg-pr-40 {
    padding-right: 40px;
  }

  .lg-pr-50 {
    padding-right: 50px;
  }

  .lg-pb-5 {
    padding-bottom: 5px;
  }

  .lg-pb-10 {
    padding-bottom: 10px;
  }

  .lg-pb-15 {
    padding-bottom: 15px;
  }

  .lg-pb-20 {
    padding-bottom: 20px;
  }

  .lg-pb-30 {
    padding-bottom: 30px;
  }

  .lg-pb-40 {
    padding-bottom: 40px;
  }

  .lg-pb-50 {
    padding-bottom: 50px;
  }

  .lg-pl-5 {
    padding-left: 5px;
  }

  .lg-pl-10 {
    padding-left: 10px;
  }

  .lg-pl-15 {
    padding-left: 15px;
  }

  .lg-pl-20 {
    padding-left: 20px;
  }

  .lg-pl-30 {
    padding-left: 30px;
  }

  .lg-pl-40 {
    padding-left: 40px;
  }

  .lg-pl-50 {
    padding-left: 50px;
  }

  .lg-m-5 {
    margin: 5px;
  }

  .lg-m-10 {
    margin: 10px;
  }

  .lg-m-15 {
    margin: 15px;
  }

  .lg-m-20 {
    margin: 20px;
  }

  .lg-m-30 {
    margin: 30px;
  }

  .lg-m-40 {
    margin: 40px;
  }

  .lg-m-50 {
    margin: 50px;
  }

  .lg-mt-5 {
    margin-top: 5px;
  }

  .lg-mt-10 {
    margin-top: 10px;
  }

  .lg-mt-15 {
    margin-top: 15px;
  }

  .lg-mt-20 {
    margin-top: 20px;
  }

  .lg-mt-30 {
    margin-top: 30px;
  }

  .lg-mt-40 {
    margin-top: 40px;
  }

  .lg-mt-50 {
    margin-top: 50px;
  }

  .lg-mr-5 {
    margin-right: 5px;
  }

  .lg-mr-10 {
    margin-right: 10px;
  }

  .lg-mr-15 {
    margin-right: 15px;
  }

  .lg-mr-20 {
    margin-right: 20px;
  }

  .lg-mr-30 {
    margin-right: 30px;
  }

  .lg-mr-40 {
    margin-right: 40px;
  }

  .lg-mr-50 {
    margin-right: 50px;
  }

  .lg-mb-5 {
    margin-bottom: 5px;
  }

  .lg-mb-10 {
    margin-bottom: 10px;
  }

  .lg-mb-15 {
    margin-bottom: 15px;
  }

  .lg-mb-20 {
    margin-bottom: 20px;
  }

  .lg-mb-30 {
    margin-bottom: 30px;
  }

  .lg-mb-40 {
    margin-bottom: 40px;
  }

  .lg-mb-50 {
    margin-bottom: 50px;
  }

  .lg-ml-5 {
    margin-left: 5px;
  }

  .lg-ml-10 {
    margin-left: 10px;
  }

  .lg-ml-15 {
    margin-left: 15px;
  }

  .lg-ml-20 {
    margin-left: 20px;
  }

  .lg-ml-30 {
    margin-left: 30px;
  }

  .lg-ml-40 {
    margin-left: 40px;
  }

  .lg-ml-50 {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .xl-p-5 {
    padding: 5px;
  }

  .xl-p-10 {
    padding: 10px;
  }

  .xl-p-15 {
    padding: 15px;
  }

  .xl-p-20 {
    padding: 20px;
  }

  .xl-p-30 {
    padding: 30px;
  }

  .xl-p-40 {
    padding: 40px;
  }

  .xl-p-50 {
    padding: 50px;
  }

  .xl-pt-5 {
    padding-top: 5px;
  }

  .xl-pt-10 {
    padding-top: 10px;
  }

  .xl-pt-15 {
    padding-top: 15px;
  }

  .xl-pt-20 {
    padding-top: 20px;
  }

  .xl-pt-30 {
    padding-top: 30px;
  }

  .xl-pt-40 {
    padding-top: 40px;
  }

  .xl-pt-50 {
    padding-top: 50px;
  }

  .xl-pr-5 {
    padding-right: 5px;
  }

  .xl-pr-10 {
    padding-right: 10px;
  }

  .xl-pr-15 {
    padding-right: 15px;
  }

  .xl-pr-20 {
    padding-right: 20px;
  }

  .xl-pr-30 {
    padding-right: 30px;
  }

  .xl-pr-40 {
    padding-right: 40px;
  }

  .xl-pr-50 {
    padding-right: 50px;
  }

  .xl-pb-5 {
    padding-bottom: 5px;
  }

  .xl-pb-10 {
    padding-bottom: 10px;
  }

  .xl-pb-15 {
    padding-bottom: 15px;
  }

  .xl-pb-20 {
    padding-bottom: 20px;
  }

  .xl-pb-30 {
    padding-bottom: 30px;
  }

  .xl-pb-40 {
    padding-bottom: 40px;
  }

  .xl-pb-50 {
    padding-bottom: 50px;
  }

  .xl-pl-5 {
    padding-left: 5px;
  }

  .xl-pl-10 {
    padding-left: 10px;
  }

  .xl-pl-15 {
    padding-left: 15px;
  }

  .xl-pl-20 {
    padding-left: 20px;
  }

  .xl-pl-30 {
    padding-left: 30px;
  }

  .xl-pl-40 {
    padding-left: 40px;
  }

  .xl-pl-50 {
    padding-left: 50px;
  }

  .xl-m-5 {
    margin: 5px;
  }

  .xl-m-10 {
    margin: 10px;
  }

  .xl-m-15 {
    margin: 15px;
  }

  .xl-m-20 {
    margin: 20px;
  }

  .xl-m-30 {
    margin: 30px;
  }

  .xl-m-40 {
    margin: 40px;
  }

  .xl-m-50 {
    margin: 50px;
  }

  .xl-mt-5 {
    margin-top: 5px;
  }

  .xl-mt-10 {
    margin-top: 10px;
  }

  .xl-mt-15 {
    margin-top: 15px;
  }

  .xl-mt-20 {
    margin-top: 20px;
  }

  .xl-mt-30 {
    margin-top: 30px;
  }

  .xl-mt-40 {
    margin-top: 40px;
  }

  .xl-mt-50 {
    margin-top: 50px;
  }

  .xl-mr-5 {
    margin-right: 5px;
  }

  .xl-mr-10 {
    margin-right: 10px;
  }

  .xl-mr-15 {
    margin-right: 15px;
  }

  .xl-mr-20 {
    margin-right: 20px;
  }

  .xl-mr-30 {
    margin-right: 30px;
  }

  .xl-mr-40 {
    margin-right: 40px;
  }

  .xl-mr-50 {
    margin-right: 50px;
  }

  .xl-mb-5 {
    margin-bottom: 5px;
  }

  .xl-mb-10 {
    margin-bottom: 10px;
  }

  .xl-mb-15 {
    margin-bottom: 15px;
  }

  .xl-mb-20 {
    margin-bottom: 20px;
  }

  .xl-mb-30 {
    margin-bottom: 30px;
  }

  .xl-mb-40 {
    margin-bottom: 40px;
  }

  .xl-mb-50 {
    margin-bottom: 50px;
  }

  .xl-ml-5 {
    margin-left: 5px;
  }

  .xl-ml-10 {
    margin-left: 10px;
  }

  .xl-ml-15 {
    margin-left: 15px;
  }

  .xl-ml-20 {
    margin-left: 20px;
  }

  .xl-ml-30 {
    margin-left: 30px;
  }

  .xl-ml-40 {
    margin-left: 40px;
  }

  .xl-ml-50 {
    margin-left: 50px;
  }
}

body {
  font-family: 'Work Sans', sans-serif;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 0;
  font-family: 'Archivo Black', sans-serif;
}

p {
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .carousel h1 {
    font-size: 15px !important;
  }

  .carousel p {
    font-size: 10px !important;
  }

  .carousel a {
    font-size: 10px !important;
  }
}

.relative {
  position: relative;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.position-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.blue {
  color: #0773DD;
}

.white {
  color: white;
}

.gray {
  color: #C4C4C4;
}

footer {
  background: #0F1C29;
  color: white;
  padding: 45px 0;
}

footer h4,
footer h5 {
  margin-top: 0;
  font-weight: bold;
}

footer p.blue {
  margin-bottom: 0;
}

.whatsapp-btn {
  display: block;
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 10;
}

.mobilePhone {
  width: 60px;
  height: 45px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.navbar {
  margin-bottom: 0;
  /*
    .benefits p {
      margin: 10px auto;
      display: table;

      span, i {
        display: table-cell;
        vertical-align: middle;
      }

      span {
        font-size: 18px;
      }

      i {
        font-size: 28px;
        padding-right: 10px;
      }
    }

    .brands .wrapper {
      max-height: 0;
      transition: all 0.3s ease;
    }*/
}

.navbar a {
  color: black;
}

.navbar .navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover,
.navbar .nav .open > a,
.navbar .nav .open > a:focus,
.navbar .nav .open > a:hover {
  background: white;
}

.navbar .benefits {
  background: #f9f9f9;
  color: #555555;
}

.navbar .navbar-brand {
  padding: 1px 0;
}

.navbar .navbar-brand img {
  width: 275px;
  height: auto;
}

.navbar .navbar-toggle .icon-bar {
  background: black;
}

.navbar .dropdown-menu {
  padding: 0;
}

.navbar .user-logged .dropdown-menu {
  padding: 14px 10px 14px;
  background: #0773DD;
}

.navbar .user-logged .dropdown-menu a {
  color: white;
  padding: 10px 15px;
}

.navbar .user-logged .dropdown-menu > li > a:focus,
.navbar .user-logged .dropdown-menu > li > a:hover {
  background: #0760c0;
}

.navbar .user-login .dropdown-menu {
  padding: 24px 24px 14px;
  background: #0773DD;
  width: 265px;
}

.navbar .user-login .dropdown-menu .user-login-alert {
  font-size: 16px;
}

.navbar .user-login .dropdown-menu input {
  outline: 0;
  background: #f2f2f2;
  border: 0;
  margin: 0 0 15px;
  padding: 10px 15px;
  width: 100%;
}

.navbar .user-login .dropdown-menu button {
  outline: 0;
  text-transform: uppercase;
  background: #FFFFFF;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #0773DD;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

/*
.slick-carousel {
  margin: 15px auto;

  .slick-slide {
    height: auto;
  }

  img {
    padding: 0 15px;
  }
}
*/

.line {
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
}

.header {
  position: relative;
}

.header .banner {
  position: relative;
  left: calc(390px + 50% - 585px);
  height: 560px;
  width: calc(780px + 50% - 585px);
  float: left;
}

.header .content {
  position: relative;
  right: calc(780px + 50% - 585px);
  background: #0773DD;
  width: calc(390px + 50% - 585px);
  float: left;
  height: 560px;
}

.header .info-container {
  max-width: 390px;
  margin-left: auto;
  height: 560px;
}

.info-container {
  background: #0773DD;
  color: white;
  padding: 50px 30px;
  position: relative;
}

.info-container .background-img {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.section {
  padding: 50px 0 50px;
}

.btn.white {
  background: white;
  color: #0773DD;
  border-radius: 20px;
  padding: 6px 50px;
}

.btn.black {
  background: black;
  color: white;
  border-radius: 20px;
  padding: 6px 50px;
}

.btn.square {
  color: black;
  background: white;
  border-color: black;
  border-radius: 0;
}

.maps-container {
  height: 600px;
}

.usa {
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
}

.usa .relative {
  height: 600px;
}

.germany {
  width: 70%;
  position: absolute;
  top: 0;
  right: 0;
}

.germany .relative {
  height: 600px;
}

.map-usa {
  position: absolute;
  top: 25%;
}

.map-germany {
  position: absolute;
  top: 25%;
  right: 0;
}

.map-usa,
.map-germany {
  color: white;
  z-index: 10;
}

.map-usa .info,
.map-germany .info {
  width: 80%;
}

.map-usa h3,
.map-germany h3 {
  font-weight: bolder;
  text-align: center;
  margin-bottom: 20px;
}

.map-usa p,
.map-germany p {
  text-align: justify;
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 510px;
}

.member-profile {
  width: 200px;
  display: inline-block;
  margin: 0 20px 20px;
  float: none;
}

.member-profile img {
  width: 100%;
}

.value {
  text-align: center;
  transition: all 0.3s;
}

.value:hover {
  color: #0773DD;
  transition: all 0.3s;
}

.value i {
  font-size: 80px;
  margin-bottom: 20px;
}

.contact-header {
  position: relative;
}

.contact-header #map {
  height: 460px;
  position: relative;
  float: left;
  width: calc(390px + 50% - 585px);
}

.contact-header .content {
  position: relative;
  width: calc(780px + 50% - 585px);
  background: #0773DD;
  float: left;
  height: 460px;
}

.contact-header .info-container {
  max-width: 780px;
  margin-right: auto;
  height: 460px;
}

.contact {
  padding: 18px;
  transition: all 0.3s;
  display: inline-block;
  margin: 0 5px 5px;
  width: 340px;
}

.contact > div {
  display: table-cell;
  vertical-align: middle;
}

.contact p {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.contact i {
  font-size: 60px;
  margin-right: 10px;
}

.contact:hover {
  background: #0C488F;
  color: white;
  transition: all 0.3s;
}

.quotation-background {
  height: 200px;
  float: left;
  width: calc(390px + 50% - 585px);
}

.quotation-form {
  width: calc(780px + 50% - 585px);
  float: left;
}

.quotation-form .form .form-control {
  margin-bottom: 24px;
}

.quotation-form .remove-item {
  background: red;
  color: white;
  padding: 8px 12px;
}

.quotation-form .foot {
  margin-top: 30px;
}

.quotation-form .foot p {
  margin-bottom: 0;
  font-weight: 300;
}

@media (max-width: 1199px) {
  .header .content {
    width: calc(324px + 50% - 485px);
    right: calc(646px + 50% - 485px);
  }

  .header .info-container {
    max-width: 324px;
  }

  .header .banner {
    width: calc(646px + 50% - 485px);
    left: calc(324px + 50% - 485px);
  }

  .quotation-background {
    width: calc(324px + 50% - 485px);
  }

  .quotation-form {
    width: calc(646px + 50% - 485px);
  }
}

@media (max-width: 991px) {
  h2 {
    font-size: 18px;
  }

  .header .banner {
    left: unset;
  }

  .header .content {
    right: unset;
  }

  .header .banner,
  .header .content,
  .contact-header #map,
  .contact-header .content,
  .quotation-background,
  .quotation-form,
  .quotation-form > form {
    width: 100%;
  }

  .header .content,
  .contact-header .content {
    height: auto;
  }

  .header .info-container,
  .contact-header .info-container {
    max-width: unset;
    margin: auto;
  }

  .header .banner {
    height: 50vw;
  }

  .contact-header #map {
    height: 200px;
  }

  .header .container {
    position: unset;
    left: unset;
    transform: unset;
    width: 100%;
  }

  .info-container {
    padding: 30px 10%;
    height: auto !important;
  }

  .maps-container {
    height: 900px;
  }

  .usa,
  .germany {
    position: unset;
    width: 100%;
  }

  .map-germany {
    top: 5%;
  }

  .map-usa,
  .map-germany {
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }

  .usa .relative,
  .germany .relative {
    height: 450px;
  }

  .usa .relative img.img-responsive,
  .germany .relative img.img-responsive {
    width: 100%;
  }

  .value {
    margin-bottom: 30px;
  }

  .section {
    padding: 30px 0 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar .benefits {
    display: none;
  }

  .navbar-right {
    float: left !important;
  }

  .gallery-wrap {
    height: 320px;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-brand img {
    margin-bottom: 5px;
  }

  .navbar-brand {
    float: unset;
  }

  .nav > li {
    text-align: center;
  }

  .navbar .user-login .dropdown-menu,
  .navbar .user-logged .dropdown-menu {
    margin: 0 auto 20px;
    width: fit-content;
    border-radius: 0;
  }

  .brands {
    width: 95%;
  }

  .benefits {
    display: none;
  }
}

.has-warning {
  border-color: yellow !important;
  color: yellow !important;
}

.loading {
  background-size: 20px 20px;
  background-position-x: 97%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-image: url("/impopartes/web/images/admin/ajax-loader-min.gif");
}

.register-link {
  color: white !important;
}

.payment-box {
  border-radius: 3px;
  border-top: 3px solid #3C53E0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  min-height: 400px;
  margin: 4px;
  padding: 16px;
}

.payment-box img {
  max-width: 100%;
  max-height: 100%;
}

